.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.theme-color,
.MuiButton-containedPrimary {
  background-color: #00953a !important; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* //<<<<<< Themes >>>>>>>// */
.Button-bg-Color
{
  background-color : #00953a !important;
  color: #ffffff !important; 
}
.header-fonts-colors
{
  color: #fff !important;
}
.Button-edit-bg-Color
{
  background-color : #007bff !important;
  color: #ffffff !important;
}
.Button-delete-bg-Color
{
  background-color : #dc3545 !important;
  color: #ffffff !important;
}
.blockedrow 
{
  background-color: #e98585 !important;
}
.assignedrow{
  background-color: #f5df99;
}
.Active-State{
  background-color: #31ab1c;
    font-size: 14px;
    color: #fff;
    border:1px solid #31ab1c ;
    border-radius: 10px;
    padding: 5px;
}
.sizeOfCels{
  max-width: 17% !important;
  width: 25%;
}
.awesomeIconSize{
  font-size: 30px;
  color : #000;
  cursor: pointer;
}
.awesomeIconSizeRight{
  font-size: 30px;
  color: #000;
  cursor: pointer;
}
.hrStyle{
  background: "#000";
  height: "1px";
  border: "none";
  margin-bottom: 4px !important;
}
.divHeight{
  height:7%
}
.divWidth{
  width: 300px !important;
}
.divPlace{
  width: 200 !important;
  font-size:12 !important;
  color:#000000 !important;
}
.bottomBtn{
  width: 150px !important;
   position:absolute !important;
  bottom: 9px !important;
}
.leadButton{
  float: left !important;
  width: 150px !important; 
  font-size:12px !important;
}
.addToMesize{
  font-size: 16px !important;
  height: 23px !important;
}
.is_assigned_button{
  display: none !important;
}
.Button-bg-assign-Color{
  background-color : #ff6600 !important;
  color: #ffffff !important; 
}
.checkSize{
  width: 20px;
}
.fontWeight{
  font-weight: 700;
}
.mt-10{
  margin-top: 88.787px;
}
.drawer{
  height: 100%;
  overflow-y: auto;
}